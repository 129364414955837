<template>
    <invite
        ref="invite"
        @inviteSent="inviteSent"
    >
    </invite>
</template>

<script>
import ecosystems from "@/services/ecosystems.service"
import Vue from "vue";

export default {
    name: "InviteOrConnectEcosystem",
    components: {
        Invite: () => import("@/components/connections/Invite"),
    },
    methods: {
        process(id) {
            ecosystems.getAccountInfo(id).then(response => {
                if (response.data.registered) {
                    if (confirm('Send connection request?')) {
                        ecosystems.addConnection({id: id}).then(response => {
                            if (!Object.keys(response.data.errors).length) {
                                this.$emit('connectionEstablished')

                                Vue.notify({
                                    group: 'app',
                                    type: 'success',
                                    duration: 5000,
                                    text: response.data.result
                                })
                            }
                        })
                    }
                }
                else {
                    this.$refs.invite.open(id)
                }
            })
        },
        inviteSent() {
            this.$emit('inviteSent')
        }
    }
}
</script>
